body {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 1em;
}
   
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--bs-primary);
    background-color: var(--bs-secondary);
}

::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid var(--bs-primary);
    border-radius: 200px;
}

.content {
    overflow: hidden;
}

.sticky-offset {
    bottom: 0;
    z-index: 1000;
}

.mont-light {
    font-family: 'Mont-Light';
}

.text-lg {
    font-size: 1.5rem !important;
}

.text-xl {
    font-size: 3rem !important;
}

.carousel-item {
    position: relative;
}

.carousel-content {
    position: absolute;
    top: 0;
}

.dropdown-item:hover {
    background-color: var(--bs-primary);
    color: white;
    font-weight: bold;
}

.nav-link:hover {
    text-decoration: underline;
}

.section-menu {
    background-image: url('../images/home/section-menu/bg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section-menu h3 {
    font-family: 'Mont-Light';
}

.section-menu img {
    max-width: 150px;
}

.thumbnail {
    width: 200px;
    height: 200px;
    object-fit: cover;
    -o-object-fit: cover;
}

.back-to-top {
    position: fixed;
    bottom: 60px;
    right: 20px;
    opacity: 0.5;
}

.back-to-top:hover {
    opacity: 1;
}

// Home
.caption-photo {
    object-fit: cover;
    -o-object-fit: cover;
}

// Gallery
.gallery-header {
    background-image: url('../images/gallery/header-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// Services
.services-header {
    background-image: url('../images/services/header-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.services img {
    object-fit: cover;
    -o-object-fit: cover;
}

// Contact
.contact-header {
    background-image: url('../images/contact/header-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// Rate
.rate-header {
    background-image: url('../images/rate/header-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.social-media {
    background-image: url('../images/rate/social-media-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// Policy
.policy-header {
    background-image: url('../images/policy/header-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
